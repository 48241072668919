export const header = `
<section style="position: relative;color: rgb(255, 255, 255);display: grid;grid-template:'search . account .' 1fr / minmax(auto, 22.5rem) 1fr auto 2.5rem;padding-left: 40px;gap: 1rem;height: 4.5rem;width: 100%;background-color: rgb(255, 255, 255);">
  <div class="react-loading-skeleton" style="grid-area: search;align-self: center;">
    <div style="height: 36px"></div>
  </div>
    <div data-testid="job-search-icon-container" style="display: flex;gap: 0.5rem;color: rgb(0, 0, 0);align-items: flex-end;flex-direction: column;justify-content: center;padding-right:14px">
        <div class="react-loading-skeleton" style="height:22px;width:100px"></div>
        <div class="react-loading-skeleton" style="height:22px;width:200px"></div>
    </div>
</section>`;
