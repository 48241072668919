import { getApiBaseUrl } from '@repairer-frontend/root-config';
import { UserData } from '@repairer-frontend/user-types';

export const login = async () => {
    const localApiGatewayUrl = getApiBaseUrl();
    const url = `${localApiGatewayUrl}/fss/auth/user`;

    const response = await fetch(url, {
        headers: { 'X-CSRF': '1', mode: 'no-cors' },
        credentials: 'include',
    });

    // Replaced the direct status code check with the `.ok` property of the
    // response object. This property checks if the status is in the range
    // 200-299, which is a more accurate way to check for a successful HTTP
    // response.
    if (!response.ok) {
        return null;
    }

    const rawUserData = await response.json();

    if (!rawUserData || rawUserData.length === 0) {
        return null;
    }

    const formattedUserData: UserData = {
        ...rawUserData,
        roles: [],
    };

    for (const item of rawUserData) {
        if (item.type === 'role') {
            formattedUserData.roles.push(item.value);
        } else {
            formattedUserData[item.type] = item.value;
        }
    }

    return formattedUserData;
};
